import React from "react";
import PropTypes from "prop-types";
import NukaCarousel from "nuka-carousel";


const Carousel = ({ slideIndex, onCarouselDrag, multiChildren, children }) => {
  return (
    <NukaCarousel
      slideIndex={slideIndex}
      afterSlide={index => onCarouselDrag(index)}
      cellSpacing={40}
      heightMode="current"
      dragging={multiChildren}
      speed={900}
      withoutControls
    >
      {children}
    </NukaCarousel>
  );
};

Carousel.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  onCarouselDrag: PropTypes.func.isRequired,
  multiChildren: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Carousel;
