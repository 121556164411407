import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rem } from "polished";

import Link from "./link";
import * as Icons from "../svg/icons";

const Root = styled(Link)`
  font-size: ${p => p.theme.smallFontSize};

  svg {
    width: ${p => p.theme.smallFontSize};
    margin-left: ${rem(`8px`)};
    vertical-align: middle;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    font-size: 20px;
  }
`;

const LinkWithIcon = ({ children, icon, fill, to }) => {
  const ActiveIcon = Icons[icon];

  return (
    <Root to={to}>
      {children}
      <ActiveIcon fill={fill} />
    </Root>
  );
};

LinkWithIcon.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkWithIcon;
