import styled from "styled-components";
import PropTypes from "prop-types";

import Text from "./text";

const Heading = styled(Text)`
  width: 100%;
  font-family: ${p => (p.slim ? p.theme.baseFont : p.theme.headingFont)};

  // probably already on rebass Text component (check)
  ${p => p.capitalize
    && `
    text-transform: capitalize;
  `}
`;

Heading.defaultProps = {
  as: `h1`,
  color: `#12253C`,
  mb: [20, null, null, 30, 40],
  fontSize: [28, null, 38, null, 48],
  // lineHeight not converting to px (without quotes)
  lineHeight: [`38px`, null, `52px`, null, `62px`],
  maxWidth: `681px`,
  mx: 0,
};

Heading.propTypes = {
  as: PropTypes.string.isRequired,
};

export default Heading;
